<template>
  <div class="frameCon">
		<div style="padding: 10px 0">
			<h2><!-- Amazon Account Help Document. -->亚马逊账号帮助文档&nbsp;</h2>
		</div>
		
		<div style="background-color: #FFFFFF;padding: 20px;">
			
		
				
				<div  class="help-b">
					<h3>1: 产品授权</h3>
					<h3>第一步: 完成amazons授权</h3>
				</div>
				<div  class="help-b">
					<p>a. 点击授权列表，在页面中有授权按钮.(如 图1)</p>
					
					<p>b. 点击授权按钮【amazon授权】，然后在浏览器中登录amazon账号</p>
					
					<p>c. 到达amazon授权明细页面 (eg：图2)</p>
					
					<p>d: 授权成功后获取授权token</p>
					<strong>图1</strong>
					<img src="../../../assets/images/amazonHelp/1.png" width="100%" height="auto" />
					
					<strong>图2</strong>
					<img src="../../../assets/images/amazonHelp/2.png" width="100%" height="auto" />
					<p>授权中..</p>
					<strong>图3</strong>
					<img src="../../../assets/images/amazonHelp/3.png" width="100%" height="auto" />
					<strong>授权成功，获取到sell partner id</strong>
					<img src="../../../assets/images/amazonHelp/4.png" width="100%" height="auto" />
				</div>
				<div  class="help-b">
					<h3>2：拉取订单</h3>
					<p>a: 选择拉取的时间（时间限制未最多前2天开始）</p>
					<p>b: 选择授权账号</p>
					<p>c: 拉取订单</p>
					<p>d: 然后使用刷新按钮 分别获取 商品信息、配送地址等</p>
					<p>e：获取订单后，使用【一件代发】功能，完成订单的自配送</p>
					<strong>图4</strong>
					<img src="../../../assets/images/amazonHelp/5.png" width="100%" height="auto" />
					<strong>图5</strong>
					<img src="../../../assets/images/amazonHelp/6.png" width="100%" height="auto" />
				</div>
				<div  class="help-b">
					<h3>3：自配送</h3>
					<p>a: 拉取到订单，并且获取了配送地址后，可以在【一件代发】菜单中提交发货信息，由我们仓库完成订单配送</p>
					<p>b: 订单提取后，系统会自动更新售卖库存，保证商品信息准确</p>
					<strong>图6</strong>
					<img src="../../../assets/images/amazonHelp/7.png" width="100%" height="auto" />
				</div>
				
			</div>
  </div>
</template>

<script>
  
  export default {
    name: "",

    data() {
      return {
        
      }
    },
    created() {
      
    },
    //离开本页面
    beforeDestroy() {
     
    },
    mounted() {
      console.log("mounted");
    },
    methods: {
      //初始化
      init() {
        
      },
     

    },
  }
</script>

<style lang='less' scoped>
  .frameCon {
		font-size: 14px;
		line-height: 150%;
		h3 {
			font-size: 18px;
		}
		.help-b {
			margin-bottom: 20px;
		}
	}
</style>
